var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M25.056 15.558c0 2.51-2.273 4.548-5.055 4.548-2.784 0-5.056-2.038-5.056-4.548C14.945 13.038 17.217 11 20 11c2.782 0 5.055 2.038 5.055 4.558zm-2.573 0c0-1.243-1.115-2.249-2.482-2.249-1.369 0-2.484 1.005-2.483 2.25 0 1.232 1.115 2.238 2.483 2.238 1.367 0 2.482-1.006 2.482-2.239zm-6.969 7.176c-1.27-.738-1.827-1.17-1.337-2.074.29-.512 1.08-.945 2.127-.2 0 0 1.415 1.017 3.698 1.017S23.7 20.46 23.7 20.46c1.048-.742 1.836-.313 2.128.2.483.904-.074 1.335-1.344 2.077v.001c-1.067.612-2.537.854-3.507.946l2.116 1.902 1.673 1.504c1.087 1.005-.682 2.576-1.781 1.605-.732-.67-1.8-1.634-2.92-2.643l-.054-.05-2.974 2.69c-1.099.966-2.868-.619-1.77-1.606l3.778-3.406c-.007.006-.137.123 0 0-.959-.091-2.45-.32-3.53-.946z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }