var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M26.492 21.358c.597.573 1.227 1.111 1.763 1.741.236.28.46.569.632.894.242.462.023.97-.4.998l-2.621-.002c-.677.055-1.216-.212-1.67-.666-.363-.363-.7-.75-1.049-1.124a2.466 2.466 0 00-.471-.412c-.358-.228-.669-.159-.874.208-.208.373-.255.786-.276 1.201-.028.606-.214.766-.835.793-1.325.062-2.583-.135-3.752-.792-1.03-.578-1.83-1.395-2.525-2.32-1.354-1.801-2.39-3.78-3.323-5.815-.21-.458-.056-.704.46-.713.855-.016 1.71-.015 2.566-.001.348.005.578.2.713.523a15.535 15.535 0 001.74 3.165c.189.263.382.525.657.71.304.204.535.136.679-.196.09-.211.13-.438.15-.664.068-.777.077-1.553-.042-2.327-.073-.483-.35-.796-.841-.887-.25-.047-.213-.139-.092-.279.21-.242.409-.393.804-.393h2.964c.466.09.57.296.634.757l.002 3.232c-.005.178.091.708.419.826.262.084.434-.122.591-.285.71-.74 1.216-1.613 1.669-2.518.2-.398.373-.81.54-1.224.124-.306.319-.457.67-.45l2.853.003c.084 0 .17 0 .252.014.48.08.612.284.464.745-.234.723-.69 1.325-1.134 1.93-.476.646-.985 1.27-1.456 1.92-.434.594-.4.893.14 1.409z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }