












































































































import { defineComponent } from '@nuxtjs/composition-api'
import SkolkovoLogo from '@/assets/img/skolkovo-logo-lk.svg'
import GooglePlayIcon from '@/assets/img/google-play.svg'
import AppStoreIcon from '@/assets/img/app-store.svg'
import VkIcon from '@/assets/img/vk-icon.svg'
import OdnoklassnikiIcon from '@/assets/img/odnoklassniki-icon.svg'
import TelegramIcon from '@/assets/img/telegram-icon.svg'
import YoutubeIcon from '@/assets/img/youtube-icon.svg'

export default defineComponent({
    components: {
        SkolkovoLogo,
        GooglePlayIcon,
        AppStoreIcon,
        VkIcon,
        OdnoklassnikiIcon,
        TelegramIcon,
        YoutubeIcon
    },
    setup () {
        const currentYear = new Date().getFullYear()

        return { currentYear }
    }
})
