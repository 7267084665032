var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer__footer"
  }, [_c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-row"
  }, [_c('div', {
    staticClass: "footer-mobile--block"
  }, [_c('div', {
    staticClass: "footer-text"
  }, [_vm._v("\n                    Скачать приложение\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "footer-btns--block"
  }, [_c('a', {
    staticClass: "footer-mobile-btn",
    attrs: {
      "href": "https://apps.apple.com/ru/app/workle-pro/id1660972579",
      "target": "_blank"
    }
  }, [_c('app-store-icon', {
    staticClass: "footer-icon"
  })], 1), _vm._v(" "), _c('a', {
    staticClass: "footer-mobile-btn",
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=ru.workle.android",
      "target": "_blank"
    }
  }, [_c('google-play-icon', {
    staticClass: "footer-icon"
  })], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "footer-social_network--block"
  }, [_c('div', {
    staticClass: "footer-text"
  }, [_vm._v("\n                    Мы в соцсетях\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "footer-btns--block"
  }, [_c('a', {
    staticClass: "footer-social_network-btn",
    attrs: {
      "href": "https://vk.com/iworkle",
      "target": "_blank"
    }
  }, [_c('vk-icon', {
    staticClass: "footer-icon"
  })], 1), _vm._v(" "), _c('a', {
    staticClass: "footer-social_network-btn",
    attrs: {
      "href": "https://ok.ru/workle",
      "target": "_blank"
    }
  }, [_c('odnoklassniki-icon', {
    staticClass: "footer-icon"
  })], 1), _vm._v(" "), _c('a', {
    staticClass: "footer-social_network-btn",
    attrs: {
      "href": "https://t.me/workle_ru",
      "target": "_blank"
    }
  }, [_c('telegram-icon', {
    staticClass: "footer-icon"
  })], 1), _vm._v(" "), _c('a', {
    staticClass: "footer-social_network-btn",
    attrs: {
      "href": "https://www.youtube.com/@worklepro",
      "target": "_blank"
    }
  }, [_c('youtube-icon', {
    staticClass: "footer-icon"
  })], 1)])])]), _vm._v(" "), _c('div', {
    staticClass: "footer-row"
  }, [_c('div', {
    staticClass: "footer-logo_block"
  }, [_c('a', {
    attrs: {
      "href": "http://sk.ru/",
      "target": "_blank"
    }
  }, [_c('skolkovo-logo', {
    staticClass: "footer__logo"
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "footer-text"
  }, [_vm._v("\n                    Резидент «Сколково»\n                ")])]), _vm._v(" "), _vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _c('div', {
    staticClass: "footer-text footer-text_info"
  }, [_vm._v("\n                © 2011‑" + _vm._s(_vm.currentYear) + "\n                Workle Pro. Все права‑защищены.\n            ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-info_block"
  }, [_c('a', {
    staticClass: "footer-link",
    attrs: {
      "href": "https://www.workle.ru/c/offer/",
      "target": "_blank"
    }
  }, [_vm._v("\n                    Условия использования\n                ")]), _vm._v(" "), _c('a', {
    staticClass: "footer-link",
    attrs: {
      "href": "https://www.workle.ru/c/privacy-policy/",
      "target": "_blank"
    }
  }, [_vm._v("\n                    Политика конфиденциальности\n                ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-text footer-text_link"
  }, [_vm._v("\n                Применяются\n                "), _c('a', {
    staticClass: "footer-link",
    attrs: {
      "href": "https://www.workle.ru/c/recommendation-technologies/",
      "target": "_blank"
    }
  }, [_vm._v("\n                    рекомендательные технологии\n                ")])]);
}]

export { render, staticRenderFns }