var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M27.464 12.277l-16.176 6.375c-1.104.453-1.097 1.082-.202 1.363l4.153 1.324 9.608-6.196c.455-.282.87-.13.528.18l-7.784 7.18h-.002.002l-.287 4.376c.42 0 .605-.197.84-.43l2.018-2.004 4.195 3.168c.774.435 1.33.211 1.522-.732l2.755-13.267c.281-1.155-.432-1.678-1.17-1.337z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }