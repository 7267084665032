var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M29.194 15.964a2.343 2.343 0 00-.589-1.04 2.276 2.276 0 00-1.019-.604c-1.428-.4-7.145-.407-7.145-.407s-5.715-.006-7.144.376a2.327 2.327 0 00-1.018.613c-.282.29-.487.649-.594 1.042-.377 1.458-.38 4.481-.38 4.481s-.004 3.039.37 4.482a2.31 2.31 0 001.609 1.643c1.443.4 7.144.407 7.144.407s5.716.006 7.145-.376a2.285 2.285 0 001.02-.602c.282-.287.486-.646.592-1.039.378-1.457.38-4.48.38-4.48s.019-3.038-.37-4.496zm-10.581 7.263l.005-5.586 4.75 2.798-4.755 2.788z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }