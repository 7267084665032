var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 120 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M22.73 8.144a4.683 4.683 0 01-1.076 3.362 3.957 3.957 0 01-3.127 1.474 4.451 4.451 0 011.105-3.237 4.794 4.794 0 013.098-1.599zm2.187 8.863a4.755 4.755 0 00-.623 2.29 4.606 4.606 0 002.82 4.238 10.544 10.544 0 01-1.466 2.978c-.864 1.289-1.77 2.547-3.208 2.57-.684.017-1.145-.18-1.626-.384-.502-.213-1.025-.435-1.843-.435-.868 0-1.414.23-1.94.45-.456.191-.897.376-1.518.402-1.37.05-2.415-1.375-3.31-2.652-1.79-2.607-3.184-7.346-1.316-10.57a5.145 5.145 0 014.32-2.627c.778-.017 1.523.281 2.176.543.5.2.946.379 1.31.379.322 0 .755-.172 1.26-.372.796-.315 1.77-.7 2.762-.596a4.906 4.906 0 013.856 2.078 4.773 4.773 0 00-1.654 1.708zM72.506 27.749c-1.58 0-2.734-.779-2.868-1.965H67.8c.116 2.163 1.912 3.532 4.572 3.532 2.844 0 4.63-1.402 4.63-3.64 0-1.748-1.021-2.734-3.483-3.314l-1.321-.324c-1.546-.38-2.178-.878-2.178-1.724 0-1.06.99-1.79 2.435-1.79 1.447 0 2.436.72 2.545 1.914h1.812c-.06-2.063-1.796-3.48-4.331-3.48-2.561 0-4.365 1.417-4.365 3.48 0 1.667 1.022 2.694 3.225 3.224l1.555.374c1.555.38 2.21.936 2.21 1.865 0 1.07-1.088 1.848-2.601 1.848z"
    }
  }), _c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M36.656 25.887h4.572l1.098 3.232h1.97l-4.332-11.96h-2.012l-4.33 11.96h1.936l1.098-3.232zm4.098-1.492H37.13l1.787-5.246h.05l1.787 5.246zM50 29.207c2.196 0 3.65-1.74 3.65-4.45 0-2.702-1.463-4.452-3.682-4.452a3.11 3.11 0 00-2.785 1.542h-.033v-1.45h-1.738V32h1.795v-4.32h.042A2.96 2.96 0 0050 29.207zm-.506-7.376c1.396 0 2.311 1.16 2.311 2.926 0 1.78-.915 2.933-2.31 2.933-1.372 0-2.295-1.176-2.295-2.933 0-1.741.922-2.926 2.294-2.926zm13.782 2.926c0 2.71-1.455 4.45-3.65 4.45a2.972 2.972 0 01-2.752-1.525h-.042V32h-1.795V20.397h1.738v1.45h.033a3.097 3.097 0 012.785-1.542c2.22 0 3.683 1.75 3.683 4.452zm-1.846 0c0-1.766-.915-2.926-2.31-2.926-1.372 0-2.295 1.185-2.295 2.926 0 1.757.922 2.933 2.294 2.933 1.396 0 2.311-1.152 2.311-2.933z"
    }
  }), _c('path', {
    attrs: {
      "d": "M80.882 18.335v2.063h1.663v1.418h-1.663v4.807c0 .746.333 1.094 1.064 1.094.198-.003.395-.017.59-.041v1.409a4.95 4.95 0 01-.996.083c-1.77 0-2.462-.663-2.462-2.354v-4.998h-1.27v-1.418h1.27v-2.063h1.804z"
    }
  }), _c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M87.658 20.29c-2.528 0-4.148 1.723-4.148 4.466 0 2.751 1.604 4.468 4.148 4.468 2.544 0 4.149-1.717 4.149-4.468 0-2.743-1.612-4.467-4.15-4.467zm0 1.473c1.454 0 2.32 1.111 2.32 2.993 0 1.89-.866 2.992-2.32 2.992-1.455 0-2.32-1.102-2.32-2.992 0-1.873.865-2.993 2.32-2.993z"
    }
  }), _c('path', {
    attrs: {
      "d": "M93.283 20.396h1.712v1.485h.042a2.09 2.09 0 012.104-1.575c.207-.002.413.02.615.066v1.674a2.514 2.514 0 00-.807-.108 1.814 1.814 0 00-1.79 1.239c-.083.247-.11.509-.08.767v5.172h-1.796v-8.72z"
    }
  }), _c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M102.268 29.224c1.978 0 3.524-1.086 3.766-2.67h-1.705a1.969 1.969 0 01-.805.948c-.365.22-.79.32-1.214.28a2.288 2.288 0 01-1.736-.706 2.277 2.277 0 01-.617-1.765v-.108h6.177v-.613c0-2.644-1.521-4.3-3.941-4.3-2.46 0-4.049 1.773-4.049 4.508 0 2.726 1.58 4.426 4.124 4.426zm2.07-5.272h-4.373a2.21 2.21 0 012.228-2.213 2.112 2.112 0 012.016 1.365c.101.27.145.56.129.848z"
    }
  }), _c('path', {
    attrs: {
      "d": "M36.627 10.584h-.77v.78h.794c.803 0 1.263.351 1.263.92 0 .562-.488.96-1.235.96-.748 0-1.254-.354-1.306-.904h-.86a1.905 1.905 0 00.721 1.308 1.92 1.92 0 001.445.393 1.932 1.932 0 001.926-.984c.123-.224.2-.47.226-.725a1.344 1.344 0 00-1.282-1.39v-.067a1.339 1.339 0 001.047-1.315c0-.912-.798-1.545-1.933-1.545a1.812 1.812 0 00-1.845.972c-.112.22-.178.462-.192.709h.846a1.02 1.02 0 011.163-.9c.68 0 1.08.342 1.08.872a.965.965 0 01-1.088.916z"
    }
  }), _c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M41.428 11.378c-1.033.064-1.617.514-1.617 1.294a1.31 1.31 0 001.45 1.298 1.469 1.469 0 001.306-.68h.068v.609h.827v-2.963c0-.916-.615-1.457-1.705-1.457-.987 0-1.69.477-1.779 1.222h.832c.096-.306.427-.482.906-.482.588 0 .89.259.89.717v.374l-1.178.068zm1.179.561v.363a1.019 1.019 0 01-1.127.948c-.467 0-.806-.227-.806-.617 0-.383.271-.586.87-.626l1.063-.068z"
    }
  }), _c('path', {
    attrs: {
      "d": "M45.737 10.286v3.616h-.858V9.565h2.864v.72h-2.006z"
    }
  }), _c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M51.152 13.974c1.098 0 1.797-.864 1.797-2.242 0-1.378-.7-2.239-1.806-2.239a1.515 1.515 0 00-1.366.789h-.064v-.717h-.826v5.779h.858v-2.099h.068a1.416 1.416 0 001.34.729zm-.248-3.712c.727 0 1.162.554 1.162 1.47 0 .92-.435 1.473-1.166 1.473-.719 0-1.17-.566-1.17-1.47 0-.907.451-1.473 1.174-1.473z"
    }
  }), _c('path', {
    attrs: {
      "d": "M54.092 15.456v-.737c.093.011.186.017.28.016a.746.746 0 00.787-.606l.064-.21-1.575-4.354h.935l1.09 3.445h.069l1.087-3.445h.91l-1.622 4.516c-.371 1.056-.783 1.394-1.67 1.394a3.728 3.728 0 01-.355-.02v.001zm5.173-2.784h-.855c.08.876.85 1.31 1.8 1.31 1.076 0 1.779-.545 1.779-1.37a.972.972 0 00-.85-.976v-.067a.894.894 0 00.686-.888c0-.733-.591-1.199-1.625-1.199a1.528 1.528 0 00-1.714 1.27h.827c.056-.342.407-.545.887-.545.527 0 .783.243.783.561 0 .363-.264.562-.783.562h-.64v.665h.65c.609 0 .924.199.924.617 0 .39-.351.642-.923.642-.503 0-.862-.2-.946-.582zm3.955 1.23V9.565h.852v2.983h.067l2.005-2.983h.851v4.337h-.85v-2.999h-.065l-2.008 3h-.851l-.001-.001zm7.09-3.616h1.371v-.72h-3.599v.72h1.37v3.616h.86l-.001-3.616z"
    }
  }), _c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M76.304 12.728a1.763 1.763 0 01-1.885 1.255 1.98 1.98 0 01-1.942-1.394 1.968 1.968 0 01-.068-.845 1.994 1.994 0 011.172-2.092c.262-.117.547-.176.834-.173 1.21 0 1.941.824 1.941 2.186v.299h-3.071v.047a1.144 1.144 0 001.158 1.243 1.046 1.046 0 001.034-.526h.827zm-3.02-1.397h2.197a1.043 1.043 0 00-1.07-1.124 1.115 1.115 0 00-1.126 1.123l-.001.001zm8.625-1.766h-1.962v4.337h1.981c.955 0 1.526-.494 1.526-1.25a.988.988 0 00-.93-1v-.068a.936.936 0 00.734-.916c0-.673-.498-1.103-1.349-1.103zm-1.11 1.78v-1.123h.945c.456 0 .672.22.672.558 0 .367-.248.565-.767.565h-.85zm0 1.9v-1.27h.91c.579 0 .886.203.886.637 0 .41-.271.633-.779.633h-1.018.001z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }